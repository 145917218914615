<template>
  <div v-if="asupId">
    <b-card>
      <b-form inline>
        <b-form-group
          label="Time range"
          label-sr-only
        >
          <date-range-picker
            v-model="timeRange"
            :timezone="timezone"
            :selectable-ranges="['last3Months', 'last6Months', 'thisYear', 'last2Years', 'last3Years', 'last5Years']"
          />
        </b-form-group>
      </b-form>
    </b-card>

    <b-row>
      <b-col>
        <!-- Daily -->
        <KpiChartAndTable
          :card-title="dayCardTitle"
          :chart-y-axis-title="dayChartYAxisTitle"
          date-formatter="DD/MM/YYYY"
          :kpi="dailyKpi"
          :timezone="timezone"
          :is-loading="isDailyKpiLoading"
        />
      </b-col>
      <b-col>
        <!-- Monthly -->
        <KpiChartAndTable
          :card-title="monthCardTitle"
          :chart-y-axis-title="monthChartYAxisTitle"
          date-formatter="YYYY MMMM"
          :kpi="monthlyKpi"
          :timezone="timezone"
          :is-loading="isMonthlyKpiLoading"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'
import {
  BRow, BCol, BCard, BForm, BFormGroup,
} from 'bootstrap-vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import KpiChartAndTable from './KpiChartAndTable.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    DateRangePicker,
    KpiChartAndTable,
  },
  props: {
    asupId: {
      type: String,
      required: true,
    },
    getKpiData: {
      type: Function,
      required: true,
    },
    timezone: {
      type: String,
      default: 'Etc/UTC',
    },
    dayCardTitle: {
      type: String,
      default: 'Job Success Rate per day',
    },
    dayChartYAxisTitle: {
      type: String,
      default: 'Job Count',
    },
    monthCardTitle: {
      type: String,
      default: 'Job Success Rate per month',
    },
    monthChartYAxisTitle: {
      type: String,
      default: 'Job Count',
    },
  },
  data() {
    return {
      timeRange: {
        range: 'thisYear',
      },
      dailyKpi: [],
      isDailyKpiLoading: false,
      monthlyKpi: [],
      isMonthlyKpiLoading: false,
    }
  },
  beforeMount() {
    this.$watch('timeRange', this.loadData)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isDailyKpiLoading = true
      this.getKpiData(this.asupId, {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        aggregateMonthly: false,
      }, { disableTenantFilter: true })
        .then(result => {
          this.dailyKpi = result.items
        })
        .finally(() => {
          this.isDailyKpiLoading = false
        })

      this.isMonthlyKpiLoading = true
      this.getKpiData(this.asupId, {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        aggregateMonthly: true,
      }, { disableTenantFilter: true })
        .then(result => {
          this.monthlyKpi = result.items
        })
        .finally(() => {
          this.isMonthlyKpiLoading = false
        })
    },
  },
}

</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
