<template>
  <b-overlay :show="isLoading">
    <b-card>
      <template #header>
        <b-card-title>{{ cardTitle }}</b-card-title>
      </template>

      <apexchart
        type="area"
        :height="chartHeight"
        :options="kpiChartOptions"
        :series="kpiChartSeries"
      />

      <div
        class="table-responsive mt-2"
      >
        <table class="table table-bordered table-sm">
          <thead>
            <tr>
              <th>Date</th>
              <th>Succeeded</th>
              <th>Failed</th>
              <th>Success Rate</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(kpiDataPoint, index) in pagedKpi"
              :key="index"
            >
              <td>{{ $moment(kpiDataPoint.dateTime).format(dateFormatter) }}</td>
              <td>{{ kpiDataPointSuccessCount(kpiDataPoint) }}</td>
              <td>{{ kpiDataPointFailedCount(kpiDataPoint) }}</td>
              <td>
                <template v-if="kpiDataPointCount(kpiDataPoint) > 0">
                  {{ kpiDataPointSuccessRate(kpiDataPoint) }}%
                </template>
                <template v-else>
                  -
                </template>
              </td>
            </tr>
          </tbody>
        </table>
        <b-pagination
          v-if="kpi.length > pagedKpi.length"
          v-model="currentPage"
          class="mt-1"
          :total-rows="kpi.length"
          :per-page="pageSize"
        />
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import { $themeColors } from '@themeConfig'
import moment from 'moment'
import {
  BOverlay, BCard, BPagination, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BOverlay,
    BCard,
    BPagination,
    BCardTitle,
    apexchart: VueApexCharts,
  },
  props: {
    cardTitle: {
      type: String,
      default: 'Job Success Rate',
    },
    chartYAxisTitle: {
      type: String,
      default: 'Job Count',
    },
    kpi: {
      type: Array,
      default: () => [],
    },
    dateFormatter: {
      type: String,
      default: 'DD/MM/YYYY',
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    chartHeight: {
      type: Number,
      default: 300,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    timezone: {
      type: String,
      default: 'Etc/UTC',
    },
  },
  data() {
    return {
      currentPage: 1,
    }
  },
  computed: {
    orderedKpi() {
      return this.kpi
        .concat()
        .sort((a, b) => (moment(a.dateTime) > moment(b.dateTime) ? -1 : 1))
    },
    pagedKpi() {
      return this.orderedKpi.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
    kpiChartSeries() {
      const now = moment()
      const beforeThan = now.startOf('day') // get only full days

      return [
        {
          name: 'Succeeded',
          data: this.kpi
            .filter(x => moment.utc(x.dateTime) < beforeThan) // get only full days
            .map(x => ([this.$moment(x.dateTime).tz(this.timezone).toISOString(), x.succeededCount])),
        },
        {
          name: 'Failed',
          data: this.kpi
            .filter(x => moment.utc(x.dateTime) < beforeThan)
            .map(x => ([this.$moment(x.dateTime).tz(this.timezone).toISOString(), x.failedCount])),
        },
      ]
    },
    kpiChartOptions() {
      const self = this
      return {
        chart: {
          height: 350,
          type: 'area',
        },
        colors: [$themeColors.success, $themeColors.danger],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        yaxis: {
          title: {
            text: self.chartYAxisTitle,
            align: 'left',
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            // eslint-disable-next-line no-unused-vars
            formatter(value, timestamp, opts) {
              return self.$moment(timestamp).format('dd.MM')
            },
          },
        },
        tooltip: {
          x: {
            formatter(val) {
              return moment(val).format('L')
            },
          },
        },
      }
    },
  },
  methods: {
    kpiDataPointSuccessCount(kpiDataPoint) {
      return kpiDataPoint.succeededCount + kpiDataPoint.activeCount + kpiDataPoint.warningCount
    },
    kpiDataPointFailedCount(kpiDataPoint) {
      return kpiDataPoint.failedCount
    },
    kpiDataPointCount(kpiDataPoint) {
      return this.kpiDataPointSuccessCount(kpiDataPoint) + this.kpiDataPointFailedCount(kpiDataPoint)
    },
    kpiDataPointSuccessRate(kpiDataPoint) {
      // eslint-disable-next-line no-mixed-operators
      return 100 - (kpiDataPoint.failedCount * 100 / this.kpiDataPointCount(kpiDataPoint)).toFixed(4)
    },
  },
}

</script>
